import React, { useState, useEffect } from 'react';
import {
  Container2,
  HeaderList,
  ImgContainer,
  List,
  SectionContainer,
  Text,
  Title,
  VideoList,
  VideoTitle,
} from './styles';
import ReactPlayer from 'react-player';
import Tooltip from '@mui/material/Tooltip';
import ClassRoomItems from 'components/layout/ClassroomItems';
import { Box, Button, Typography } from '@mui/material';
import { fetchWithToken } from 'helpers/fetch';
import { endPoints } from 'const/endPoints';
import Toast from 'components/common/Popup/Toast';
import DownloadIcon from '@mui/icons-material/Download';
import Stack from '@mui/material/Stack';
import DescriptionIcon from '@mui/icons-material/Description';
import { truncate } from "helpers/truncate";
import { Lessons } from 'components/layout/CourseTab/Lessons';
import Divider from '@mui/material/Divider';
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import parse from 'html-react-parser'
var FileSaver = require('file-saver');

const drawerWidth = {
  width: '70%',
  ['@media (min-width:780px)']: {
    width: '50%'
  }
}
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}px`,

    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  })
);


const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end"
}));

export const VideoLesson = ({
  lesson,
  lessonsAndQuizzes,
  date,
  cleanTitle,
  lessonId,
  section,
  course,
  resources
}) => {

  const [currentLink, setCurrentLink] = useState('')
  const [links, setLinks] = useState([])
  const [header, setHeader] = useState('video')
  const [loading, setLoading] = useState(false);
  const [isPay, setIsPay] = useState(false);
  const description = truncate(lesson.description, 100);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true); 
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const checkPayment = async () => {
      const res = await fetchWithToken(
        `${endPoints.check_payment}/${course.id}`
      );

      const isPay = await res.json();
      if (res.status === 401) {
        setIsPay(false);
        return;
      }
      setIsPay(isPay);
    };
    checkPayment();
  }, []);



  useEffect(async () => {
    setCurrentLink('')
    setLinks([])
    const resp = await fetchWithToken(`${endPoints.get_video_by_lesson}/${lessonId}`)
    const data = await resp.json();
    console.log(data.links)
    for (let i = 0; i < 5; i++) {
      if (data.links[i].url != '') {
        setCurrentLink(data.links[i])
        i = 5 
      }
    }
    setLinks(data.links)
    console.log(data)
  }, [lessonId])
  const handleResource = async (item) => {
    FileSaver.saveAs(item.path, item.filename)
    Toast('success', "Resource Downloaded successfully");
  }

  const handleResolution = (index) => {
    setCurrentLink(links[index])
  }
  // const firstNextLesson = nextSection[0];
  // const newType = type === 'clase' ? 'clase' : 'quiz';

  // const nextPath =
  //   firstNextLesson &&
  //   `/course/classroom/${cleanTitle}/${newType}/${firstNextLesson.id}`;
  return (
    <>
      <Box sx={{ display: "flex" }} marginLeft={10}>
        <CssBaseline />
        <Tooltip title="Toda la sección">
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box"
            }
          }}
          onBackdropClick={handleDrawerClose}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Typography variant="h4" textAlign="center" color="primary">Toda la sección</Typography>
          <Divider />
          <div>
            <Stack
              direction="column"
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
              marginBottom={10}
            >
              <Lessons
                courseId={course.id}
                courseTitle={course.title}
                isPay={isPay}
                current={section}
                handleDrawerClose={handleDrawerClose}
                fromDrawer={true}
                slice={true}
              />
            </Stack>
          </div>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
        </Main>
      </Box>
      <Box
        sx={{
          height: 'calc(100vh - 84px)',
          backgroundColor: ' #f8f8f8',
        }}
      >
        <SectionContainer>
          <ImgContainer>
            <ReactPlayer
              url={currentLink.url}
              width="100%"
              height="100%"
              controls
              playing
              style={{
                // make minimalistic player
                objectFit: 'fill',
                objectPosition: 'cenfter',
                backgroundColor: '#0f0f0f',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                border: 'none',
              }}
              config={{
                file: {
                  attributes: {
                    autoPlay: false,
                    controlsList: 'nodownload'
                  },
                },
              }}
            />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              marginTop="10px"
            >
              {
                links.map((link, index) => {
                  return <Button key={index} variant='contained' size="medium" disabled={link.url == ''} color={currentLink.name === link.name ? "success" : "primary"}
                    onClick={() => handleResolution(index)}>{link.name} px
                  </Button>
                })
              }
            </Stack>
            <VideoTitle>
              <h1>{lesson.name}</h1>
              <h2>{date}</h2>
              <h3>{parse(lesson.description)}</h3>
            </VideoTitle>
          </ImgContainer>
          <VideoList>
            <HeaderList>
              <Title>
                <h1>{section.name}</h1>
              </Title>
              <Text>
                <h5><Button variant="simple" onClick={() => setHeader('video')}>Video</Button></h5>
                <h5><Button variant="simple" onClick={() => setHeader('resource')}>Recursos</Button></h5>
              </Text>
            </HeaderList>
            {header === 'video' ? <List>
              {lessonsAndQuizzes.map((item, index) => {
                const lessonType = item.status ? 'quiz' : 'clase';
                return (
                  <ClassRoomItems
                    key={index}
                    type={lessonType}
                    courseTitle={cleanTitle}
                    lessonId={lessonId}
                    item={item}
                  />
                );
              })}
            </List> :
              <List>
                <Stack direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}>
                  {resources.map((item, index) => {
                    return (
                      <div key={index}>
                        <Stack direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <DescriptionIcon color="primary" />
                          <Button onClick={() => handleResource(item)}>{item.filename}<DownloadIcon color="success" /></Button>
                        </Stack>
                      </div>
                    );
                  })}
                </Stack>
              </List>
            }
          </VideoList>
        </SectionContainer>
      </Box >
    </>

  );
};
